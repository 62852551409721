<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto min-h-96',
        padding: 'sm:pt-4'
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="settings-packs"
        title="Settings packs"
      />
    </template>
    <UButton
      v-if="can('settings.settings_packs.manage_settings_packs')"
      color="black"
      variant="soft"
      icon="i-heroicons-plus-20-solid"
      size="sm"
      class="w-fit bg-gray-100 flex justify-center"
      label="Create pack"
      @click="openSettingsPackModifyTab()"
    />
    <div class="mt-4 flex flex-col gap-y-4">
      <SettingsPackItemCard
        v-for="pack in settingsPacks"
        :key="pack.id"
        :item="pack"
        @click="onPreviewSettingsPackClick(pack)"
      >
        <template #footer>
          <div class="mt-3 flex justify-end">
            <UButton
              color="gray"
              size="sm"
              @click="openSettingsPackModifyTab(pack.id)"
            >
              Edit
            </UButton>
            <UButton
              v-if="source === 'board' && !isBoardInSettingsPack(pack)"
              color="green" size="sm" class="ml-2"
              @click="onApplyToBoard(pack.id)"
            >
              Apply to current board
            </UButton>
          </div>
        </template>
      </SettingsPackItemCard>
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import type { SettingsPack } from '#settings-pack/types'
import { SETTINGS_PACKS_QUERY } from '#settings-pack/schema'

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  }
})

const { can } = useWorkspaceAbility()
const { currentWorkspace, setCurrentSettingsPack } = useWorkspaceSharedState()
const toast = useToast()

const { setSettingTab } = useBoardSettingsNavigator()
const { toggleAssignSettingsPack } = useToggleSettingsPack()

const { result } = useQuery<{ settingsPacks?: SettingsPack[] }>(
  SETTINGS_PACKS_QUERY,
  {
    workspaceId: currentWorkspace.value.id,
  }
)

const settingsPacks = computed(() => result.value?.settingsPacks)

const isBoardInSettingsPack = (settingsPack: SettingsPack) => {
  return settingsPack?.boards?.some(board => board.id === props.parentId)
}

const onApplyToBoard = async (settingsPackId: string) => {
  await toggleAssignSettingsPack(settingsPackId, [props.parentId])
  toast.add({
    title: 'Setting pack applied',
    description: `Product development pack was successfully assigned to your board.`,
    icon: 'i-heroicons-check-circle',
  })
}

const onPreviewSettingsPackClick = (settingsPack: SettingsPack) => {
  setCurrentSettingsPack(settingsPack)
  setSettingTab('settingsPackPreview')
}

const openSettingsPackModifyTab = (settingsPackId?: string) => {
  let url = `/w/${currentWorkspace.value.handle}/settings-packs#settings-pack`
  if (settingsPackId) {
    url = `/w/${currentWorkspace.value.handle}/settings-packs?id=${settingsPackId}#settings-pack`
  }

  navigateTo(url, { open: { target: '_blank' } })
}
</script>
